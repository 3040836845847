import _ from 'lodash'

export const sanitize = function (value) {
  try {
    var pattern = /[^a-zA-Z0-9-_]+/gi
    let sanate = value
    sanate = _.replace(String(sanate).trim(), ' ', '_')
    sanate = sanate.replace(pattern, '_').toLowerCase()
    return sanate
  } catch {
    return ''
  }
}
