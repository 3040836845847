<template>
  <v-row>
    <v-col xs="12" sx="12" md="12" xl="12">
      <v-card flat :loading="loading">
        <v-card-title>
          Elenco impostazioni
          <v-btn icon class="ml-3">
            <v-icon @click="getDirList">mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <dettaglio-impostazioni :configurazioni="configurazioni" :triggerRefresh="triggerRefresh" @delete="onDeleteFile" @deleteFolder="onDeleteFolder" @refresh="getDirList" @save="onSaveFile"/>     
        </v-card-text>    
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import utility from '@/services/utilityService'
  import impostazioni from '@/services/impostazioniService'
  // import FileDirList from './fileDirList'
  import DettaglioImpostazioni from './dettaglio'

  export default {
    components: {
      DettaglioImpostazioni
    },
    data () {
      return {
        elencoFile: '',
        loading: false,
        files: [],
        moduli: [],
        filesInFolder: [],
        defaultDati: {
          codice: '',
          titolo: '',
          note: '',
          categoria: 'Generale',
          gruppo: 'Cassa',
          posizione: '',
          default: true,
          daPersonalizzare: true,
          tag: 'tutti',
          payload: {
          },
          meta: {
          }
        },
        defaultMd:'##',
        configurazioni: [],
        triggerRefresh: 1
      }
    },
    async mounted() {
      await this.getDirList()
      // await this.getModuleVersion()
    },
    methods: {
      onClearFile() {
        this.elencoFile = ''
      },
      async getDirList() {
        this.configurazioni = await impostazioni.getAllInfo(true)
      },
      async onDeleteFile(item) {
        const pathFile = 'configs/' + item.dir + '/' + item.file
        await utility.deleteFile(pathFile)
        await this.getDirList()
      },
      async onDeleteFolder(folder) {
        const id = folder.replaceAll('\\', '-').replaceAll('/', '-').toLowerCase()
        await impostazioni.cancellaConfigurazione(id)
        await this.$nextTick()
        await this.getDirList()
      },
      async onSaveFile(folder, datiJson, datiMd) {
        let dati = datiJson || _.cloneDeep(this.defaultDati)
        dati.codice = dati.codice || uuidv4()
        dati.info = datiMd || _.cloneDeep(this.defaultMd)
        // defaultDati.codice = uuidv4()
        const document = {
          _id: folder.replaceAll('\\', '-').replaceAll('/', '-').toLowerCase(),
          dati: dati,
          path: folder.toLowerCase()
        }
        await impostazioni.aggiornaConfigurazione(document)
        await this.getDirList()
      }
    }
  }
</script>
