<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            label="Cerca impostazioni"
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title> 
    <v-row no-gutters>
      <v-col cols="4">
        <v-card-title>
          <v-btn small title="Crea un elemento di primo livello" @click="creaElementoRoot">Crea elemento root</v-btn>
        </v-card-title>        
        <v-card-text>
          <v-treeview
            :items="items"
            :search="search"
            :open.sync="open"
            dense
            hoverable
            activatable
            item-key="id"
            item-text="descrizione"
            :open-on-click="true"
            :return-object="true"
            transition
            @update:active="onItemSelected"
            @update:open="onUpdateOpen"
          >
          <template v-if="abilitaModificaElementi" v-slot:prepend="{ item }">
            <v-icon small @click="editFolderItem(item)" title="Modifica l'elemento">mdi-pencil</v-icon>
          </template>
            <template v-slot:append="{ item }">
              <v-btn v-if="!item.children || item.children.length === 0" icon @click.stop="deleteFolder(item)" title="Cancella l'elemento"><v-icon small>mdi-delete</v-icon></v-btn>
              <v-btn v-if="aggiungiElementoVisibile(item)" icon @click.stop="onNewItem(item)" title="Aggiungi un elemento figlio" ><v-icon small>mdi-plus-circle-outline</v-icon></v-btn>
            </template>          
          </v-treeview>
        </v-card-text>
        <v-card-actions>
          <!-- Al momento non abilitato -->
          <!-- <v-switch v-model="abilitaModificaElementi" label="Abilita modifica nodi" inset></v-switch> -->
        </v-card-actions>        
      </v-col>
      <v-col cols="3">
        <v-card-subtitle>
          <v-row no-gutters>
            <v-col><span class="mt-2 mb-1 text-caption grey--text text--lighten-1">{{ pathId }}</span></v-col>
            <v-col></v-col>
            <v-col><v-btn v-if="mostraCreaElementi" @click.stop="creaElementi()" small color="primary" dark>Crea elementi</v-btn></v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-textarea v-show="markdownInfo" v-model="markdownInfo" class="px-2 text-caption" outlined auto-grow @keydown="onChangeInput"></v-textarea>      
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn còass="mr-5" small color="success" :disabled="!daSalvare" @click.stop="salvaFile()">Salva</v-btn>
        </v-card-actions>
      </v-col>
      <v-col cols="5">
        <v-json-editor v-if="jsonWebS" v-model="jsonWebS" :options="optionsWeb" :plus="true" history @error="onErrorWeb" @input="onChangeInput" style="height: 100%;" ref="jsonEditorS"/>
      </v-col>
    </v-row>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false"
    />
    <v-dialog v-model="dialogNewItem" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Nuovo elemento</v-card-title>
        <v-card-text>
          <v-container dense>
            <v-row><v-col><v-text-field v-model="newItem.codice" label="Nome cartella" hide-details clearable clear-icon="mdi-close-circle-outline" @keyup.enter="editItemSave"></v-text-field></v-col></v-row>
            <!-- <v-row><v-col><v-text-field v-model="editedItem.descrizione" label="Descrizione" hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field></v-col></v-row> -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editItemSave">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditItem" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Modifica elemento</v-card-title>
        <v-card-text>
          <v-container dense>
            <v-row><v-col><v-text-field v-model="newItem.codice" label="Nome cartella" hide-details clearable clear-icon="mdi-close-circle-outline" @keyup.enter="editFolderSave"></v-text-field></v-col></v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editFolderSave">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import DialogConferma from '@/components/dialogConferma'
import VJsonEditor from 'v-jsoneditor'
import { sanitize } from '@/helpers/sanitize'

export default {
  components: {
    VJsonEditor,
    DialogConferma
  },
  props: {
    configurazioni: Array,
    triggerRefresh: Number
  },
  data(vm) {
    return {
      expanded: true,
      dialogConferma: false,
      dialogEditItem: false,
      dialogNewItem: false,
      editItem: null,
      newItem: {},
      titoloConferma: '',
      messaggioConferma: '',
      onConferma: vm.deleteFolderConfirm,
      itemToDelete: null,
      search: null,
      open: [],
      items: [],
      filesInFolder: [],
      pathId: '',
      jsonMode: 'code',
      optionsWeb: {
        mode: 'code',
        modes: ['view', 'form', 'code'],
        search: true,
        enableSort: false,
        enableTransform: true
      },
      jsonWebS: '',
      markdownInfo: '',
      headers: [
        {
          text: 'File',
          align: 'start',
          sortable: false,
          value: 'file'
        },
        { text: 'Data', value: 'data' },
        { text: 'Azioni', value: 'actions', sortable: false, width: "10%" }
      ],
      daSalvare: false,
      defaultRootItem: {
        id: '',
        codice: '',
        descrizione: '',
        children: []
      },
      elementoSelezionato: null,
      itemNuoviFile: null,
      forceUpdate: false,
      separatore: '/',
      abilitaModificaElementi: false,
      mostraCreaElementi: false
    }
  },
  computed: {
  },
  watch: {
    async configurazioni() {
      await this.refresh()
    },
    async triggerRefresh() {
      while(this.items.length > 0) { this.items.pop() }
      this.jsonWebS = ''
      this.markdownInfo = ''      
      await this.refresh()
    }
  },
  mounted() {
    this.items = this.generaAlberoDir(this.configurazioni)
  },
  methods: {
    onChangeInput() {
      this.daSalvare = true
    },
    creaElementoRoot() {
      this.newItem = {
        isRoot: true,
        codice: ''
      }
      this.dialogNewItem = true
      this.daSalvare = true
    }, 
    async refresh() {
      const items = this.generaAlberoDir(this.configurazioni)
      if (!this.items || this.items.length === 0 || this.forceUpdate) {
        this.forceUpdate = false
        this.items = items
      }
      if (this.itemNuoviFile) {
        await this.refreshContenuto(this.itemNuoviFile)
        this.itemNuoviFile = null
      }      
    },
    creaElementi(item) {
      this.itemNuoviFile = this.elementoSelezionato
      console.log(this.pathId)
      this.$emit('save', this.pathId)
    },
    salvaFile() {
      this.$emit('save', this.pathId, this.jsonWebS, this.markdownInfo)
      this.daSalvare = false
    },
    aggiungiElementoVisibile(item) {
      let visibile = item.children && item.children.length > 0
      if (!visibile) {
        const foundEl = this.findRecurse(this.items, item.id)
        const pathId = foundEl.pathId.split('.').reverse().join(this.separatore)
        const el = this.configurazioni.find(x => x.path === pathId)
        visibile = !el
      }
      return visibile
    },
    generaAlberoDir(configurazioni) {
      let items = []
      for (let f of configurazioni) {
        if (!f.path) {
          console.log(f)
          continue
        }
        // deve cambiare il separatore solo se lo trova nel percorso
        this.separatore = f.path.includes('\\') || f.path.includes('/') ? f.path.includes('\\') ? '\\' : '/' : this.separatore

        let d = f.path.split(this.separatore)
        let rm = items.find(x => x.codice === d[0])
        if (!rm) {
          let al = {
            codice: d[0],
            descrizione: d[0],
            id: uuidv4()
          }
          if (d.length > 1) {
            al.children = this.generaDirRicorsivo(configurazioni.map(x => x.path).filter(x => x && x.startsWith(d[0])).map(x => x.split(this.separatore).slice(1).join(this.separatore)), this.separatore)
          }
          items.push(al)
        }
      }
      return items.filter(x => x.codice !== '_setup')
    },
    generaDirRicorsivo(configurazioni, sep) {
      let items = []
      for (let f of configurazioni) {
        let d = f.split(sep)
        let rm = items.find(x => x.codice === d[0])
        if (!rm) {
          let al = {
            codice: d[0],
            descrizione: d[0],
            id: uuidv4()
          }
          if (d.length > 1) {
            al.children = this.generaDirRicorsivo(configurazioni.filter(x => x.startsWith(d[0])).map(x => x.split(sep).slice(1).join(sep)), sep)
          }
          items.push(al)
        }
      }
      return items
    },
    onRefresh() {
      this.$emit('refresh')
    }, 
    async refreshContenuto(el) {
      const foundEl = this.findRecurse(this.items, el.id)
      if (!foundEl) {
        this.jsonWebS = ''
        this.markdownInfo = ''
        return
      }
      const pathId = foundEl.pathId.split('.').reverse().join(this.separatore)
      this.pathId = pathId
      const item = this.configurazioni.find(x => x.path === pathId)
      if (item) {
        this.jsonWebS = item.dati
        this.markdownInfo = item.info
        this.mostraCreaElementi = false
      } else {
        this.jsonWebS = ''
        this.markdownInfo = ''
        this.mostraCreaElementi = true
      }
    },
    async onItemSelected(elementi) {
      let el = elementi && elementi.length > 0 ? elementi[0] : {}
      if (!_.isEmpty(el)) {
        this.elementoSelezionato = el
        await this.refreshContenuto(el) 
      } else {
        this.elementoSelezionato = null
      }
    },
    findRecurse(items, id) {
      var item
      for (let el of items) {
        if (el.id === id) {
          item = el
          item.pathId = el.codice
        } else {
          if (el.children) {
            item = this.findRecurse(el.children, id)
            if (item) {
              item.pathId = item.pathId + '.' + el.codice
            }
          }
        }
        if (item) {
          break
        }
      }
      return item
    },
    deleteFolder(item) {
      this.titoloConferma = "Cancellazione cartella"
      this.messaggioConferma = 'Confermi la cancellazione della cartella ?'
      this.onConferma = this.deleteFolderConfirm
      this.itemToDelete = item
      this.dialogConferma = true
    },
    async deleteFolderConfirm() {
      this.forceUpdate = true
      const foundEl = this.findRecurse(this.items, this.itemToDelete.id)
      const pathId = foundEl.pathId.split('.').reverse().join(this.separatore)
      this.$emit('deleteFolder', pathId)
      this.jsonWebS = ''
      this.markdownInfo = ''      
      this.itemToDelete = null
      this.dialogConferma = false
    },    
    onUpdateOpen(elementi) {
      this.expanded = elementi.length > 0
    },    
    onNewItem(item) {
      this.editItem = item
      this.newItem = {
        codice: ''
      }
      this.dialogNewItem = true
    },
    async editItemSave() {
      let el = {
        id: uuidv4(),
        codice: sanitize(this.newItem.codice),
        descrizione: sanitize(this.newItem.codice)
      }
      if (this.newItem.isRoot) {
        this.items.push(el)
      } else {
        let foundEl = this.findRecurse(this.items, this.editItem.id)
        if (!foundEl.children) {
          this.$set(foundEl, "children", []);
        }
        foundEl.children.push(el)
        this.open.push(this.editItem)
        this.onUpdateOpen(this.open)
      }
      this.dialogNewItem = false
      this.daSalvare = true
    },
    async rigaSelezionata(item, data, event) {
    },
    onErrorWeb() {},
    editFolderItem(item) {
      this.editItem = item
      this.newItem.codice = item.codice
      this.dialogEditItem = true
    },
    editFolderSave() {
      const item = this.editItem
      var foundEl = this.findRecurse(this.items, item.id)
      const oldCodice = foundEl.codice
      foundEl.codice = sanitize(this.newItem.codice)
      foundEl.descrizione = sanitize(this.newItem.codice)
      let els = foundEl.pathId.split('.')
      els[0] = foundEl.codice
      foundEl.pathId = els.join('.')
      this.pathId = foundEl.pathId.split('.').reverse().join(this.separatore)
      this.changeFolderRecurse(foundEl, oldCodice, foundEl.codice)
      this.daSalvare = true
      this.dialogEditItem = false
      this.editItem = null
    },
    changeFolderRecurse(foundEl, oldCodice, newCodice) {
      if (foundEl.children && foundEl.children.length > 0) {
        for (let c of foundEl.children) {
          let oldPathId = c.pathId 
          let els = c.pathId.split('.')
          let elx = els.findIndex(x => x === oldCodice)
          if (elx > -1) {
            els[elx] = newCodice
          }
          c.pathId = els.join('.')
          if (c.children && c.children.length > 0) {
            this.changeFolderRecurse(c, oldCodice, newCodice) 
          } else {
            // non completa
            const pathId = oldPathId.split('.').reverse().join(this.separatore)
            let item = this.configurazioni.find(x => x.path === pathId)
            let els = oldPathId.split('.')
            els[0] = newCodice
            item.path = els.reverse().join(this.separatore).toLowerCase()
          }
        }
      } else {
        const pathId = foundEl.pathId.split('.').reverse().join(this.separatore)
        let item = this.configurazioni.find(x => x.path === pathId)
        let els = foundEl.pathId.split('.')
        els[0] = newCodice
        item.path = els.reverse().join(this.separatore).toLowerCase()
      }
    }
  }
}
</script>
